<template>
  <q-dialog persistent>
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section class="text-center">
        <div class="text-body1 q-pb-md">
          {{ $t("Profile.wait") }}
        </div>
        <q-spinner size="40px" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}
</style>
